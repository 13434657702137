import {
  ArrowRightIcon,
  Avatar,
  Dialog,
  DialogBody,
  DialogHeader,
  DialogModal,
  DialogTitle,
  FlaskIcon,
  IconButton,
  Input,
  OverlayCloseButton,
  Size,
  SparkleIcon,
  XMinorIcon,
} from '@pledge-earth/product-language';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import type { MenuItemProps } from 'react-aria-components';
import {
  UNSTABLE_Autocomplete as Autocomplete,
  Header,
  Menu,
  MenuItem,
  MenuSection,
  SearchField,
  useFilter,
} from 'react-aria-components';
import { useLocation } from 'react-router-dom';

import { useIsTestMode } from '../../hooks/useIsTestMode';
import { useNavigateWithTestMode } from '../../hooks/useNavigateWithTestMode';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { themeChanged } from '../../store/settings/reducers';

export function CommandPalette() {
  const [isOpen, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const theme = useAppSelector((state) => state.settings.theme);
  const isTestMode = useIsTestMode();
  const { pathname } = useLocation();
  const navigateWithTestMode = useNavigateWithTestMode();

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'k' && e.metaKey) {
        setOpen(!isOpen);
      }
    };
    document.addEventListener('keydown', handleKeyDown, true);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [isOpen]);

  const { contains } = useFilter({ sensitivity: 'base' });

  return (
    <DialogModal isOpen={isOpen} onOpenChange={setOpen}>
      <Dialog>
        <DialogHeader>
          <DialogTitle>Quick search</DialogTitle>
          <OverlayCloseButton label="Close" />
        </DialogHeader>
        <DialogBody>
          <Autocomplete filter={contains}>
            <SearchField aria-label="Search for a command" autoFocus={true} className="flex w-full flex-col gap-1">
              <Input
                placeholder="Start typing to search for a command..."
                className="w-full pr-0"
                size={Size.Loose}
                suffix={
                  <IconButton label="Clear" variant="plain" size={Size.Compact}>
                    <XMinorIcon />
                  </IconButton>
                }
              />
            </SearchField>
            <Menu className="mt-4 flex flex-col gap-2" onAction={() => setOpen(false)}>
              <MenuSection className="flex flex-col gap-1">
                <Header className="font-galano-grotesque font-medium text-default-bold">Actions</Header>
                <CommandItem
                  onAction={() => dispatch(themeChanged({ theme: theme === 'light' ? 'dark' : 'light' }))}
                  textValue="Toggle theme"
                  icon={<SparkleIcon />}
                >
                  Toggle theme
                </CommandItem>
                <CommandItem
                  onAction={() => navigateWithTestMode(pathname, { testMode: !isTestMode })}
                  textValue="Toggle test mode"
                  icon={<FlaskIcon />}
                >
                  Toggle test mode
                </CommandItem>
              </MenuSection>
              <MenuSection className="flex flex-col gap-1">
                <Header className="font-galano-grotesque font-medium text-default-bold">Navigate</Header>
                <CommandItem href="/emissions" textValue="Emissions" icon={<ArrowRightIcon />}>
                  Emissions
                </CommandItem>
                <CommandItem href="/calculators/freight" textValue="Calculator" icon={<ArrowRightIcon />}>
                  Calculator
                </CommandItem>
                <CommandItem href="/reports/emissions" textValue="Reports" icon={<ArrowRightIcon />}>
                  Reports
                </CommandItem>
                <CommandItem href="/directory/client" textValue="Client directory" icon={<ArrowRightIcon />}>
                  Client directory
                </CommandItem>
                <CommandItem href="/directory/supplier" textValue="Supplier directory" icon={<ArrowRightIcon />}>
                  Supplier directory
                </CommandItem>
              </MenuSection>
            </Menu>
          </Autocomplete>
        </DialogBody>
      </Dialog>
    </DialogModal>
  );
}

function CommandItem(props: Omit<MenuItemProps, 'className'> & { icon: ReactNode }) {
  return (
    <MenuItem
      {...props}
      className="flex h-10 flex-row items-center gap-2 rounded px-2 text-body-md text-default focus:bg-accent-subdued focus:text-default-hover"
    >
      {(values) => (
        <>
          <Avatar variant="square" size="24" className="text-default-subdued">
            {props.icon}
          </Avatar>

          {typeof props.children === 'function' ? props.children(values) : props.children}
        </>
      )}
    </MenuItem>
  );
}
